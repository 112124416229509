import React, { useEffect, useState } from 'react';
import { urls } from '../_rego.config';
import axios from 'axios';
import { showToast } from '../../../helpers/alert';
import { buildUrl } from '../../../helpers/utils';
import Portal from '../../../components/atoms/Portal';
import { filename } from '../../../helpers/str';
import FileDownload from '../../../components/molecules/FileDownload';

const DocumentPreview = (props) => {
    const { config, show, documents } = props;
    const [showDrawer, setShowDrawer] = useState(show || false);
    const [files, setFiles] = useState([]);
    useEffect(() => {}, [documents, config]);

    const handleShowDrawer = async () => {
        const tmp = await getData();
        if (config?.download !== true) {
            setShowDrawer(true);
        } else if (tmp[0]?.url) {
            let popup = window.open(tmp[0]?.url, '_blank');
            if (!popup || popup.closed || typeof popup.closed === 'undefined') {
                showToast('error', 'Popup window is blocked, Please enable the popup window');
            }
        }
    };

    const getData = async () => {
        const tmp = [];
        for (const document of documents) {
            let response = await axios.get(
                buildUrl(urls.storage_documents_details, { id: document.storage_id })
            );
            response = response.data.files;
            for (const element of response) {
                try {
                    const { data } = await axios.get(
                        buildUrl(urls.storage_file, { file_path: element.file_name })
                    );
                    element.url = data.url;
                    element.name = document.name;
                    element.storage_id = document.id;
                    tmp.push(element);
                } catch (error) {}
            }
        }
        setFiles(tmp);
        return tmp;
    };

    return (
        <>
            <span
                className='text-primary cursor-pointer'
                onClick={handleShowDrawer}>
                {(config?.icon && <i className={`text-primary ${config.icon}`}></i>) ||
                    (config?.btn && (
                        <button className='btn btn-sm me-2 btn-icon btn-light-primary btn-circle'>
                            <i className={config.btn}></i>
                        </button>
                    )) ||
                    config.label ||
                    'View File'}
            </span>
            {showDrawer && (
                <Portal id='root-drawers'>
                    <div
                        style={{ zIndex: 109 }}
                        className='drawer-overlay'
                        onClick={() => setShowDrawer(false)}></div>
                    <div
                        className={`bg-body drawer drawer-on drawer-${config.position}`}
                        style={{ width: '60%' }}>
                        <div className='card shadow-none rounded-0 w-100'>
                            <div
                                className='card-header'
                                id='kt_help_header'>
                                <h5 className='card-title fw-bold text-gray-600'>{config.title}</h5>
                                <div className='card-toolbar'>
                                    <button
                                        type='button'
                                        onClick={() => setShowDrawer(false)}
                                        className='btn btn-sm btn-icon explore-btn-dismiss me-n5'>
                                        <i className='bi bi-x-lg'></i>
                                    </button>
                                </div>
                            </div>
                            <div
                                className='card-body'
                                id='kt_help_body'>
                                {files &&
                                    files.map((item, index) => {
                                        return (
                                            <React.Fragment key={`document_preview_${index}`}>
                                                {
                                                    <>
                                                        <div className='d-flex w-100 justify-content-between align-items-center'>
                                                            <h3>
                                                                {' '}
                                                                File {index + 1} (
                                                                {filename(item.url)}){' '}
                                                            </h3>
                                                            <FileDownload fileUrl={item.url} />
                                                        </div>
                                                    </>
                                                }
                                                {item.mime_type.indexOf('image') !== -1 &&
                                                    item.url && (
                                                        <img
                                                            src={item.url}
                                                            alt={item.url}
                                                            className='mw-100 mh-100'
                                                        />
                                                    )}
                                                {item.mime_type === 'application/zip' && item.url && (
                                                    <svg
                                                        width='100%'
                                                        height='180'
                                                        xmlns='http://www.w3.org/2000/svg'>
                                                        <rect
                                                            width='100%'
                                                            height='180'
                                                            fill='gray'
                                                        />
                                                        <text
                                                            x='50%'
                                                            y='50%'
                                                            dominantBaseline='middle'
                                                            textAnchor='middle'
                                                            fill='white'
                                                            fontSize='36'
                                                            fontFamily='Arial'>
                                                            ZIP
                                                        </text>
                                                    </svg>
                                                )}
                                                {item.mime_type.indexOf('image') === -1 &&
                                                    item.mime_type !== 'application/zip' &&
                                                    item.url && (
                                                        <iframe
                                                            className='w-100 h-100'
                                                            title={item.url}
                                                            src={item.url}
                                                            sandbox='allow-same-origin allow-scripts'
                                                            csp="default-src 'none'; script-src 'none'; style-src 'self';"
                                                        />
                                                    )}
                                                <div className='separator separator-dashed my-5'></div>
                                            </React.Fragment>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    );
};

export default DocumentPreview;
