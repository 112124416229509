export const ROUTE_URLS = {
    /* URLS v2 Begin */
    master: '/crm/borrowers/',
    collections: '/crm/collections/',
    kyc_pending: '/crm/borrowers/kyc_pending',
    bre_pending: '/crm/borrowers/bre_pending',
    activation: '/crm/borrowers/activation',
    disbursed: '/crm/borrowers/disbursed',
    kyc_approvals: '/crm/approvals/kyc/',
    bre_approvals: '/crm/approvals/bre/',

    runners: '/crm/borrowersV2/',
    runner_details: '/crm/borrowersV2/:id/',

    accounts: '/los/accounts/',
    accounts_pending_underwriting: '/los/accounts/pending_underwriting/',
    accounts_signing: '/los/accounts/signing/',
    accounts_mandates: '/los/accounts/mandates/',
    accounts_pdcs: '/los/accounts/pdcs/',
    accounts_fee: '/los/accounts/fee/',
    accounts_insurance: '/los/accounts/insurance/',
    accounts_assessments: '/los/accounts/assessments/',

    credit_approvals: '/los/approvals/credit/',
    activation_approvals: '/los/approvals/activation/',
    topup_approvals: '/disbursal/approvals/topup/',
    topup_anomaly_approvals: '/disbursal/approvals/topup_anomaly/',

    payout_approvals: '/disbursal/approvals/payout/',

    record_approvals: '/accounting/approvals/record/',
    contact_create: '/crm/contact/create/',
    contact: '/crm/contact/',
    contact_details: '/crm/contact/details',

    document_list: '/crm/documents/',

    /* URLS v2 End */

    dashboard: '/dashboard/',
    reports: '/reports/',
    dashboard_disbursal: '/dashboard/disbursal',

    auth: '/auth/',
    auth_login: '/auth/login/',
    auth_programs: '/auth/programs/',

    plugins: '/admin/plugins/',

    templates: '/admin/templates/',
    template_create: '/admin/templates/create/',

    tasks: '/tasks/',
    approvals: '/approvals/',

    leads: '/crm/leads/',
    leads_create: '/crm/leads/create/',
    leads_import: '/crm/leads/import/',

    applications: '/los/applications/',
    applications_underwriting: '/los/applications/underwriting/',
    applications_review: '/los/applications/:account/review/',
    assessments: '/los/assessments/',

    users: '/admin/users/',
    user_roles: '/admin/users/roles/',
    user_permissions: '/admin/users/permissions/',

    escrow: '/admin/escrow/',

    my_company: '/admin/company/',
    companies: '/admin/companies/',

    programs: '/admin/programs/',
    programs_active: '/admin/programs/active/',

    emi_utils: 'emis/utils/',

    flows: '/admin/flows/',
    flow_details: '/admin/flows/:id/',
    flow_json: '/admin/flows/:id/json/',
    flow_offers: '/admin/flows/:id/offers/',
    flow_secrets: '/admin/flows/:id/secrets/',

    configs: '/admin/configs/',

    constants: '/admin/constants/',

    gl: '/accounting/gl/',
    gl_accounts: '/accounting/gl/accounts/',
    gl_transactions: '/accounting/gl/transactions/',

    topups: '/disbursal/topups/',
    payouts: '/disbursal/payouts/',

    tasks_disburse: '/tasks/disburse/',
    tasks_accounting: '/tasks/accounting/',
    tasks_gl: '/tasks/gl/',
    tasks_lms: '/tasks/lms/',

    disbursal_approvals: '/disbursal/approvals/',
    disbursal_tasks: '/disbursal/background-tasks/',
    disbursal_accounts: '/disbursal/accounts/',
    disbursal_payouts: '/disbursal/payouts/',
    disbursal_cashback: '/disbursal/excess_balance/cashback',
    disbursal_holdback: '/disbursal/excess_balance/holdback',

    accounting_accounts: '/accounting/accounts/',
    accounting_loans: '/accounting/loans/',
    accounting_records: '/accounting/records/',
    accounting_ledger: '/accounting/ledger/',

    co_lending_loans: '/co_lending/loans/',
    co_lending_payouts: '/co_lending/payouts/',

    developers: '/developers/',

    demand_sheet: '/accounting/demand-sheet/',

    logout: '/logout',
    switch_program: '/switch-program',
};
